.std-button {
	width: 141px;
	height: 48px;
	text-align: center;
	border-radius: 5px;
	opacity: 1;
	font: normal normal normal 16px/32px New-Spirit;
	letter-spacing: -0.32px;
	color: #262626;
	letter-spacing: 0px;
	z-index: 888;
	&--white {
		background: white 0% 0% no-repeat padding-box;
		box-shadow: 0px 4px 0px #f0f0f0;
		border: 2px solid white;
		&:hover {
			transform: translateY(2px);
			height: 48px;
			background: #f8f8f8 0% 0% no-repeat padding-box;
			border: 2px solid #f8f8f8;
			box-shadow: 0px 2px 0px #f0f0f0;
		}
		&:focus {
			outline: transparent;
		}
	}
	&--yellow {
		background: $ms-yellow 0% 0% no-repeat padding-box;
		box-shadow: 0px 4px 0px #debe27;
		border: 2px solid $ms-yellow;
		&:hover {
			transform: translateY(2px);
			height: 48px;
			background: #f7d303 0% 0% no-repeat padding-box;
			border: 2px solid #f7d303;
			box-shadow: 0px 2px 0px #debe27;
		}
		&:focus {
			outline: transparent;
		}
	}
	&--yellow-white {
		background: $ms-yellow 0% 0% no-repeat padding-box;
		box-shadow: 0px 4px 0px #f0f0f0;
		border: 2px solid white;
		&:hover {
			transform: translateY(2px);
			height: 48px;
			background: white 0% 0% no-repeat padding-box;
			border: 2px solid white;
			box-shadow: 0px 2px 0px #f0f0f0;
		}
		&:focus {
			outline: transparent;
		}
	}
	&--blue {
		color: white;
		background: $ms-blue 0% 0% no-repeat padding-box;
		box-shadow: 0px 4px 0px #1c5aa5;
		border: 2px solid $ms-blue;
		&:hover {
			transform: translateY(2px);
			height: 48px;
			background: #2b6bb9 0% 0% no-repeat padding-box;
			border: 2px solid #2b6bb9;
			box-shadow: 0px 2px 0px #1c5aa5;
		}
		&:focus {
			outline: transparent;
		}
	}
	&--blue.disabled {
		opacity: 50%;
		&:hover {
			transform: initial;
			background: $ms-blue 0% 0% no-repeat padding-box;
			box-shadow: 0px 4px 0px #1c5aa5;
			border: 2px solid $ms-blue;
		}
	}
	&--blue.floatLeft {
		float: left
	}
	&--blue.floatRight {
		float: right
	}
	&--green {
		color: white;
		background: $ms-green 0% 0% no-repeat padding-box;
		box-shadow: 0px 4px 0px #43a35c;
		border: 2px solid $ms-green;
		&:hover {
			transform: translateY(2px);
			height: 48px;
			background: #3ab95b 0% 0% no-repeat padding-box;
			border: 2px solid #3ab95b;
			box-shadow: 0px 2px 0px #43a35c;
		}
		&:focus {
			outline: transparent;
		}
	}
	&--green.disabled {
		opacity: 50%;
		&:hover {
			transform: initial;
			background: $ms-green 0% 0% no-repeat padding-box;
			box-shadow: 0px 4px 0px #43A35C;
			border: 2px solid $ms-green;
		}
	}
	&--green.floatLeft {
		float: left
	}
	&--green.floatRight {
		float: right
	}
	&--purple {
		color: white;
		background: $ms-purple 0% 0% no-repeat padding-box;
		box-shadow: 0px 4px 0px #7220ba;
		border: 2px solid $ms-purple;
		&:hover {
			transform: translateY(2px);
			height: 48px;
			background: #8531ce 0% 0% no-repeat padding-box;
			border: 2px solid #8531ce;
			box-shadow: 0px 2px 0px #7220ba;
		}
		&:focus {
			outline: transparent;
		}
	}
	&--purple.disabled {
		opacity: 50%;
		&:hover {
			transform: initial;
			background: $ms-purple 0% 0% no-repeat padding-box;
			box-shadow: 0px 4px 0px #7220ba;
			border: 2px solid $ms-purple;
		}
	}
	&--purple.floatLeft {
		float: left
	}
	&--purple.floatRight {
		float: right
	}
	&--black {
		color: white;
		background: #262626 0% 0% no-repeat padding-box;
		box-shadow: 0px 4px 0px #000000;
		border: 2px solid #262626;
		&:hover {
			transform: translateY(2px);
			height: 48px;
			background: #1b1b1b 0% 0% no-repeat padding-box;
			border: 2px solid #1b1b1b;
			box-shadow: 0px 2px 0px #000000;
		}
		&:focus {
			outline: transparent;
		}
	}
}

.mindful-button {
	width: 276px;
}

.skill-button {
	width: 200px;
}

.about-button {
	width: 226px;
}

.journal-button {
	width: 169px;
}

.close-window {
	width: 169px;
	margin-top: 50px;
}

.mobile-navbar {
	width: 100%;
}
