.exercise-tile {
    height: 600px;
    @include media-breakpoint-down("md") {
        height: 900px;
    }
    .content {
		width: 1366px;
        @include media-breakpoint-only("lg") {
			width: 992px;
		}
		@include media-breakpoint-only("xl") {
			width: 1200px;
		}
		@include media-breakpoint-down("md") {
			width: 100vw;
		}
	}
	.row {
		margin-top: 3rem;
		@include media-breakpoint-down("sm") {
			margin-top: auto;
		}
	}
	img {
		width: 402px;
		height: 393px;
		margin-right: 7rem;
        @include media-breakpoint-up("md") {
			width: 348px;
			height: 340px;
		}
        @include media-breakpoint-only("md") {
            margin-right: auto;
			margin-left: auto;
        }
		@include media-breakpoint-down("sm") {
			width: 278px;
			height: 272px;
			margin-right: auto;
			margin-left: auto;
		}
	}
	h2 {
		font: normal normal normal 32px/45px New-Spirit;
		letter-spacing: -0.64px;
		color: #262626;
		width: 536px;
		height: 125px;
        margin-top: 40px;
        @include media-breakpoint-up("md") {
            font: normal normal normal 30px/42px New-Spirit;
            width: 414px;
        }
        @include media-breakpoint-only("md") {
            margin-right: auto;
			margin-left: auto;
        }
        @include media-breakpoint-down("sm") {
            font: normal normal normal 28px/40px New-Spirit;
            width: 314px;
            height: 150px;
            margin-right: auto;
            margin-left: auto;
        }
	}
	p {
		text-align: left;
		font: normal normal normal 18px/30px New-Spirit;
		letter-spacing: -0.36px;
		color: #262626;
		opacity: 0.75;
		width: 536px;
		height: 203px;
        margin-top: 40px;
        @include media-breakpoint-up("md") {
            font: normal normal normal 17px/28px New-Spirit;
            width: 414px;
            height: 350px;
        }
        @include media-breakpoint-only("md") {
            margin-right: auto;
			margin-left: auto;
        }
        @include media-breakpoint-down("sm") {
            font: normal normal normal 16px/25px New-Spirit;
            width: 314px;
            height: 450px;
            margin-top: 30px;
            margin-right: auto;
            margin-left: auto;
        }
	}
}
