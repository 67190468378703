.sign-up {
	background-image: url(../../imgs/background/dotted-lines.svg);
	background-repeat: no-repeat;
	background-size: 600px 520px;
	background-position: center;
	box-shadow: 0px 4px 0px #debe27;
	border: 2px solid $ms-yellow;
	width: 536px;
	height: 420px;
	background-color: $ms-yellow;
	border-radius: 10px;
	margin-top: 50px;
	@include media-breakpoint-down("md") {
		width: 316px;
		height: 425px;
	}
	.on-track {
		padding-top: 18px;
	}
	h3 {
		text-align: center;
		font: normal normal normal 32px/45px New-Spirit;
		letter-spacing: -0.64px;
		color: #262626;
		margin-top: 20px;
		@include media-breakpoint-down("md") {
			text-align: left;
			font: normal normal normal 20px/30px New-Spirit;
			margin-left: 24px;
		}
	}
	p {
		width: 368px;
		height: 53px;
		text-align: center;
		font: normal normal normal 18px/30px New-Spirit;
		letter-spacing: -0.36px;
		color: #262626;
		opacity: 0.75;
		margin-top: 24px;
		margin-bottom: 24px;
		@include media-breakpoint-down("md") {
			width: 268px;
			height: 80px;
			font: normal normal normal 16px/30px New-Spirit;
			margin-left: 24px;
		}
	}
	input {
		width: 428px;
		height: 50px;
		text-align: center;
		font: normal normal normal 18px/30px New-Spirit;
		letter-spacing: -0.36px;
		background: #ffffff 0% 0% no-repeat padding-box;
		border-radius: 5px;
		opacity: 1;
		border: none;
		float: none;
		color: #262626;
		display: block;
		margin-left: auto;
		margin-right: auto;
		@include media-breakpoint-down("md") {
			width: 280px;
		}
		&:focus {
			outline-color: #757575;
			color: #262626;
			font: normal normal normal 18px/30px New-Spirit;
		}
	}
	.invalid-feedback {
		font: normal normal normal 14px/20px New-Spirit;
		height: 25px;
		width: 428px;
		margin-top: 5px;
		margin-bottom: 5px;
		margin-left: auto;
		margin-right: auto;
	}
	.button-container {
		justify-content: center;
		display: flex;
		margin-top: 20px;
		@include media-breakpoint-down("md") {
			justify-content: center;
			margin-top: 16px;
			margin-right: auto;
		}
	}
	.subscribe {
		font: normal normal normal 20px/15px New-Spirit;
		margin-left: auto;
		margin-right: auto;
		height: 25px!important;
		display: none;
		@include media-breakpoint-down("md") {
			font: normal normal normal 18px/15px New-Spirit;
		}
		&--success {
			color: green!important;
			display: block
		}
		&--error {
			color: red!important;
			display: block
		}
	}
}
