.preview {
	width: 260px;
	margin-right: 16px;
	margin-bottom: 32px;
	cursor: pointer;
	position: relative;
	@include media-breakpoint-down("md") {
		width: 314px;
	}
	&-blue {
		img {
			box-shadow: 0px 4px 0px #1c5aa5;
		}
		&:hover {
			img {
				transform: translateY(2px);
				height: 189px;
				box-shadow: 0px 2px 0px #1c5aa5;
			}
			h3 {
				color: #2674d1;
			}
		}
	}
	&-green {
		img {
			box-shadow: 0px 4px 0px #43a35c;
			background-color: #44cc67;
		}
		&:hover {
			img {
				transform: translateY(2px);
				height: 189px;
				box-shadow: 0px 2px 0px #43a35c;
			}
			h3 {
				color: #44cc67;
			}
		}
	}
	&-purple {
		img {
			box-shadow: 0px 4px 0px #7220ba;
		}
		&:hover {
			img {
				transform: translateY(2px);
				height: 189px;
				box-shadow: 0px 2px 0px #7220ba;
			}
			h3 {
				color: #7220ba;
			}
		}
	}
	&-no-margin {
		margin-right: 0px;
	}
	.skill-type {
		position: absolute;
		top: 49px;
		left: 20px;
		text-align: left;
		font: normal normal normal 18px/30px New-Spirit;
		letter-spacing: -0.36px;
		color: #262626;
		opacity: 1;
	}
	.skill-name {
		position: absolute;
		top: 80px;
		left: 20px;
		text-align: left;
		font: normal normal normal 30px/32px New-Spirit;
		letter-spacing: -0.7px;
		color: #262626;
		opacity: 1;
		width: 204px;
		height: 134px;
	}
	.skill-icon {
		background-repeat: no-repeat;
		background-position-x: center;
		background-size: cover;
		width: 61px;
		height: 61px;
		right: 12px;
		top: 12px;
		position: absolute;
		&-worksheet {
			background-image: url(../../imgs/icons/worksheet.svg);
		}
		&-skill {
			background-image: url(../../imgs/icons/skill.svg);
		}
	}
	.video-icon {
		background-repeat: no-repeat;
		background-position-x: center;
		background-size: cover;
		width: 40px;
		height: 40px;
		left: 15px;
		top: 136px;
		position: absolute;
		background-image: url(../../imgs/icons/play.svg);
	}
	&:hover {
		.skill-name {
			transform: translateY(2px);
		}
		.skill-type {
			transform: translateY(2px);
		}
		.skill-icon {
			transform: translateY(2px);
		}
		.video-icon {
			transform: translateY(2px);
		}
	}

	img {
		width: 260px;
		height: 189px;
		border-radius: 10px;
		@include media-breakpoint-down("md") {
			width: 314px;
		}
	}
	h3 {
		text-align: left;
		font: normal normal normal 18px/30px New-Spirit;
		letter-spacing: -0.36px;
		color: #262626;
		width: 260px;
		margin-top: 16px;
	}
}
