.conversation-tile {
	background-image: url(../../../imgs/background/blue-bg.svg);
	background-repeat: no-repeat;
	width: 100vw;
	height: 1200px;
	background-position-x: center;
	background-size: cover;
	z-index: 1;
	@include media-breakpoint-down("sm") {
		height: 2065px;
	}
	@include media-breakpoint-only("lg") {
		height: 1250px;
	}
	@include media-breakpoint-only("md") {
		height: 1450px;
	}
	.content {
		width: 1366px;
		@include media-breakpoint-only("xl") {
			width: 1200px;
		}
		padding-top: 300px;
		@include media-breakpoint-down("md") {
			width: 100vw;
			padding-top: 110px;
		}
		@include media-breakpoint-only("lg") {
			width: 100vw;
			padding-top: 95px;
		}
	}
	.box {
		@include media-breakpoint-down("lg") {
			width: 100vw;
			justify-content: center;
			text-align: center;
			h2 {
				text-align: center;
			}
		}
	}
	.convo-icon {
		@include media-breakpoint-down("xs") {
			margin-right: 75px;
		}
	}
	h2 {
		text-align: left;
		font: normal normal normal 32px/45px New-Spirit;
		letter-spacing: -0.64px;
		color: #262626;
		margin-top: 40px;
		@include media-breakpoint-only("lg") {
			margin-top: 25px;
		}
		@include media-breakpoint-down("xs") {
			margin-right: 25px;
			font: normal normal normal 28px/40px New-Spirit;
		}
	}
	p {
		text-align: left;
		font: normal normal normal 18px/30px New-Spirit;
		letter-spacing: -0.36px;
		color: #262626;
		opacity: 0.75;
		width: 482px;
		height: 150px;
		margin-top: 24px;
		margin-bottom: 24px;
		@include media-breakpoint-only("lg") {
			height: 115px;
			margin-top: 20px;
			margin-bottom: 0px;
		}
		@include media-breakpoint-down("sm") {
			font: normal normal normal 16px/25px New-Spirit;
			width: 314px;
			margin-left: 30px;
		}
	}
	.preview-tiles {
		@include media-breakpoint-down("lg") {
			margin-top: 40px;
			.preview {
				margin-left: auto !important;
				margin-right: auto !important;
			}
		}
	}
	.see-all-btn {
		@include media-breakpoint-down("lg") {
			text-align: center;
		}
		@include media-breakpoint-down("xs") {
			margin-right: 36px;
		}
	}
}

.modal-dialog.video-modal {
	max-width: 853px !important;
	@include media-breakpoint-only("md") {
		max-width: 600px !important;
		max-height: 100vh !important;
	}
	@include media-breakpoint-down("sm") {
		max-width: 314px !important;
		max-height: 100vh !important;
	}
}

.video-modal {
	width: 853px;
	height: 480px;
	margin-top: 170px;
	@include media-breakpoint-only("md") {
		width: 600px;
	}
	@include media-breakpoint-down("sm") {
		width: 314px;
		height: 467px !important;
		margin-left: auto;
		margin-right: auto;
	}
	
	
	.modal-content {
		height: 480px;
		background-color: transparent;
		border: none;
		@include media-breakpoint-down("md") {
			height: 467px;
		}
		.modal-body {
			height: 480px;
			padding: 0;
			border: none;
			justify-content: center;
			display: flex;
			@include media-breakpoint-down("md") {
				height: 400px;
			}
		}
	}

}

.video-responsive {
	overflow: hidden;
	padding-bottom: 56.25%;
	height: 0;
  }
  
  .video-responsive iframe {
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	position: absolute;
  }