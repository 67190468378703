.mind-mapper {
	background-image: url(../../../imgs/background/mind-mapper-bg.svg);
	background-repeat: no-repeat;
	background-position-x: center;
	background-size: cover;
	width: 100vw;
	height: 100vh;
	&-emotions {
		height: auto;
		min-height: 100vh;
	}
	.resources-container {
		@include media-breakpoint-up("xl") {
			width: 1088px;
		}
		@include media-breakpoint-only("lg") {
			width: 100vw;
		}
		margin-top: 50px;
		@media (min-width: 1500px) {
			width: 1188px;
		}
	}
	.content {
		position: relative;
		width: 1366px;
		@include media-breakpoint-only("lg") {
			width: 992px;
		}
		@include media-breakpoint-only("xl") {
			width: 1200px;
		}
		height: 100vh;
		@include media-breakpoint-down("md") {
			width: 100vw;
		}
		h1 {
			text-align: center;
			font: normal normal normal 40px/45px New-Spirit;
			letter-spacing: -0.8px;
			color: #262626;
			opacity: 1;
			@include media-breakpoint-down("md") {
				font: normal normal normal 32px/40px New-Spirit;
			}
		}
		h2 {
			text-align: center;
			font: normal normal normal 30px/40px New-Spirit;
			letter-spacing: -0.6px;
			color: #262626;
			opacity: 1;
			@include media-breakpoint-down("md") {
				width: 314px;
				font: normal normal normal 32px/40px New-Spirit;
			}
		}
		p {
			width: 720px;
			height: 120px;
			text-align: center;
			font: normal normal normal 18px/30px New-Spirit;
			letter-spacing: -0.36px;
			color: #262626;
			opacity: 1;
			margin-left: auto;
			margin-right: auto;
			margin-top: 32px;
			margin-bottom: 32px;
			@include media-breakpoint-down("md") {
				width: 314px;
				height: 200px;
				font: normal normal normal 16px/25px New-Spirit;
				opacity: 0.75;
				margin-top: 16px;
				margin-bottom: 26px;
			}
		}
		.redirect {
			text-align: center;
			font: normal normal normal 18px/32px New-Spirit;
			letter-spacing: -0.36px;
			color: #262626;
			opacity: 0.75;
			margin-top: 24px;
			&:hover {
				text-decoration: none;
				opacity: 0.9;
				cursor: pointer;
			}
		}
	}
	.content-resources {
		position: relative;
		width: 100vw;
		h1 {
			margin-top: 144px;
			text-align: center;
			font: normal normal normal 32px/45px New-Spirit;
			letter-spacing: -0.64px;
			color: #262626;
			opacity: 1;
			@include media-breakpoint-down("md") {
				font: normal normal normal 32px/40px New-Spirit;
			}
		}
		p {
			width: 720px;
			text-align: center;
			font: normal normal normal 18px/30px New-Spirit;
			span {
				font-weight: 500;
			}
			letter-spacing: -0.36px;
			color: #262626;
			opacity: 1;
			@include media-breakpoint-down("md") {
				width: 312px;
				font: normal normal normal 16px/25px New-Spirit;
				opacity: 0.75;
				letter-spacing: -0.32px;
				margin-top: 16px;
				margin-bottom: 40px;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
	.shame {
		@media (min-width: 990px) {
			height: 300px;
			width: 300px;
			background-size: 300px 249px;
		}
		@media (min-width: 767px) {
			height: 200px;
			width: 200px;
			right: 25px;
			top: 85px;
			position: absolute;
			background-image: url(../../../imgs/moods/shame.svg);
			background-repeat: no-repeat;
			background-size: 200px 149px;
			transform: rotate(-20deg);
		}
		@include media-breakpoint-down("sm") {
			height: 150px;
			width: 150px;
			background-size: 100px 100px;
			@media screen and (max-height:600px){
				height: 80px;
				width: 80px;
				background-size: 80px 80px;
			}
			right: 0px;
			top: 100px;
			position: absolute;
			background-image: url(../../../imgs/moods/shame.svg);
			background-repeat: no-repeat;
			background-position-x: center;
			background-position-y: center;
			transform: rotate(20deg);
		}
	}
	.sad {
		@media (min-width: 990px) {
			height: 350px;
			width: 350px;
			background-size: 350px 300px;
		}
		@media (min-width: 767px) {
			height: 250px;
			width: 250px;
			left: 25px;
			top: 100px;
			position: absolute;
			background-image: url(../../../imgs/moods/sad.svg);
			background-repeat: no-repeat;
			background-size: 250px 199px;
			transform: rotate(20deg);
		}
		@include media-breakpoint-down("sm") {
			height: 150px;
			width: 150px;
			background-size: 120px 150px;
			@media screen and (max-height:600px){
				height: 80px;
				width: 80px;
				background-size: 80px 80px;
			}
			left: 0px;
			top: 100px;
			position: absolute;
			background-image: url(../../../imgs/moods/sad.svg);
			background-repeat: no-repeat;
			background-position-x: center;
			background-position-y: center;
			transform: rotate(-20deg);
		}
	}
	.anger {
		@media (min-width: 990px) {
			height: 400px;
			width: 400px;
			background-size: 400px 349px;
		}
		@media (min-width: 767px) {
			height: 300px;
			width: 300px;
			left: 25px;
			bottom: 0px;
			position: absolute;
			background-image: url(../../../imgs/moods/anger.svg);
			background-repeat: no-repeat;
			background-position-x: center;
			background-position-y: center;
			background-size: 300px 249px;
		}
		@include media-breakpoint-down("sm") {
			height: 150px;
			width: 150px;
			background-size: 100px 120px;
			@media screen and (max-height:600px){
				height: 80px;
				width: 80px;
				background-size: 80px 80px;
			}
			left: 0px;
			bottom: 50px;
			position: absolute;
			background-image: url(../../../imgs/moods/anger.svg);
			background-repeat: no-repeat;
			background-position-x: center;
			background-position-y: center;
			transform: rotate(-20deg);
		}
	}
	.happy {
		@media (min-width: 990px) {
			height: 400px;
			width: 400px;
			background-size: 400px 349px;
		}
		@media (min-width: 767px) {
			height: 300px;
			width: 300px;
			right: 25px;
			bottom: 0px;
			position: absolute;
			background-image: url(../../../imgs/moods/happy.svg);
			background-repeat: no-repeat;
			background-position-x: center;
			background-position-y: center;
			background-size: 300px 249px;
		}
		@include media-breakpoint-down("sm") {
			height: 150px;
			width: 150px;
			background-size: 120px 120px;
			@media screen and (max-height:600px){
				height: 80px;
				width: 80px;
				background-size: 80px 80px;
			}
			right: 0px;
			bottom: 50px;
			position: absolute;
			background-image: url(../../../imgs/moods/happy.svg);
			background-repeat: no-repeat;
			background-position-x: center;
			background-position-y: center;
		}
	}
}

.feeling-select {
	@include media-breakpoint-down("sm") {
		height: 1600px;
	}
	@include media-breakpoint-between("sm", "md") {
		height: 120vh;
	}

	.content {
		p {
			width: 720px;
			height: 35px;
			text-align: center;
			font: normal normal normal 18px/30px New-Spirit;
			letter-spacing: -0.36px;
			color: #262626;
			opacity: 1;
			margin-left: auto;
			margin-right: auto;
			margin-top: 32px;
			margin-bottom: 32px;
			@include media-breakpoint-down("sm") {
				width: 314px;
				height: 120px;
				font: normal normal normal 16px/25px New-Spirit;
				opacity: 0.75;
				margin-top: 16px;
				margin-bottom: 26px;
			}
		}
	}
	.box {
		padding-top: 144px;
		@include media-breakpoint-down("sm") {
			padding-top: 150px;
			height: auto;
		}
		p {
			opacity: 0.75;
			margin-top: 16px;
			margin-bottom: 16px;
			@include media-breakpoint-down("sm") {
				height: 45px;
				width: 262px;
			}
		}
		.p2 {
			height: 65px;
			margin-bottom: 30px;
			@include media-breakpoint-down("sm") {
				height: 130px;
			}
		}
		.row {
			width: 800px;
			@include media-breakpoint-down("sm") {
				width: 100vw;
			}
		}
		.col-sm-6 {
			width: auto;
			height: 250px;
		}
	}
}

.feeling-clicked {
	@include media-breakpoint-down("md") {
		height: 1200px;
	}
	.box {
		padding-top: 144px;
		@include media-breakpoint-down("md") {
			padding-top: 150px;
			height: auto;
		}
		p {
			opacity: 0.75;
			@include media-breakpoint-down("md") {
				height: auto;
			}
		}
		.mood-col {
			height: 200px;
			margin-top: 30px;
			margin-bottom: 10px;
			@include media-breakpoint-down("md") {
				margin-top: 30px;
				margin-bottom: 20px;
			}
		}
		.next-button {
			margin-top: 15px;
			@include media-breakpoint-down("md") {
				margin-top: 0px;
			}
		}
	}
}

.feeling-display {
	user-select: none;
	.mood {
		height: 170px;
		width: 170px;
		background-repeat: no-repeat;
		background-position-x: center;
		background-position-y: center;
		justify-content: center;
		display: flex;
		@include media-breakpoint-down("md") {
			height: 150px;
			width: 150px;
		}
		&:hover {
			opacity: 1;
			cursor: pointer;
		}
	}
	p {
		font: normal normal normal 18px/30px New-Spirit;
		width: 60px !important;
		opacity: 1 !important;
		text-transform: capitalize;
		&:hover {
			cursor: pointer;
		}
	}

	$list: (
		"happy",
		"sad",
		"guilt",
		"love",
		"anger",
		"jealous",
		"shame",
		"fear"
	);
	@each $mood in $list {
		.#{$mood}-bg {
			background-image: url(../../../imgs/moods/backgrounds/#{$mood}-bg.svg);
			opacity: 0.5;
			background-size: 170px 170px;
			@include media-breakpoint-down("md") {
				background-size: 150px 150px;
			}
		}
		.#{$mood}-face {
			background-image: url(../../../imgs/moods/white/#{$mood}.svg);
			@include media-breakpoint-down("md") {
				background-size: 90px 90px;
			}
		}
	}

	.selected {
		opacity: 1;
		cursor: default !important;
		&:hover {
			cursor: default !important;
		}
	}
}

.rate-feeling {
	@include media-breakpoint-down("md") {
		height: 1300px;
	}
	.box {
		padding-top: 144px;
		@include media-breakpoint-down("md") {
			padding-top: 150px;
			height: auto;
		}
		.p-desc {
			margin-bottom: 40px;
			@include media-breakpoint-down("md") {
				height: 200px;
				width: 262px;
			}
		}
		p {
			opacity: 0.75;
			margin-top: 16px;
			@include media-breakpoint-down("md") {
				height: 45px;
				width: 262px;
			}
		}
		.row {
			width: 800px;
			padding-top: 45px;
			@include media-breakpoint-down("md") {
				width: 100vw;
			}
		}
		.mood-col {
			height: 200px;
			margin-top: 30px;
			margin-bottom: 10px;
			@include media-breakpoint-down("md") {
				margin-top: 30px;
				margin-bottom: 20px;
			}
		}
		.col-sm-6 {
			width: auto;
			height: 250px;
		}
	}
	.btn-rate {
		width: 30px;
		height: 30px;
		background: #ffda2d 0% 0% no-repeat padding-box;
		border-radius: 50%;
		font: normal normal normal 15px/19px New-Spirit;
		letter-spacing: -0.3px;
		text-align: center;
		padding: 0rem 0rem;
		margin-left: auto;
		margin-right: auto;
		z-index: 2;
		&.active {
			background: #fff8cc 0% 0% no-repeat padding-box;
			border: 2px solid #ffda2d;
			opacity: 1;
		}
		&:focus {
			background: #fff8cc 0% 0% no-repeat padding-box;
			border: 2px solid #ffda2d;
			opacity: 1;
			box-shadow: none;
		}
	}

	.line {
		width: 650px;
		height: 0px;
		border: 4px solid #ffda2d;
		margin-top: 11px;
		margin-bottom: 11px;
		position: absolute;
	}

	.rate-descr {
		height: 23px;
		margin-top: auto;
		margin-bottom: auto;
		text-align: center;
		font: normal normal normal 18px/23px New-Spirit;
		color: #262626;
		letter-spacing: -0.36px;
	}
	.next-button {
		margin-top: 36px;
	}
}
