.toolkit-tile {
    background-image: url(../../../imgs/background/green-bg.svg);
	background-repeat: no-repeat;
	width: 100vw;
	height: 1159px;
	background-position-x: center;
	background-size: cover;
    z-index: 2;
    margin-top: -180px;
    @include media-breakpoint-down("sm") {
        height: 2000px;
        margin-top: -300px;
    }
	@include media-breakpoint-only("lg") {
		height: 1191px;
		margin-top: -187px;
	}
	@include media-breakpoint-only("md") {
		height: 1397px;
		margin-top: -215px;
	}
    .content {
		width: 1366px;
		@include media-breakpoint-only("lg") {
			width: 992px;
			padding-top: 200px;
		}
		@include media-breakpoint-only("xl") {
			width: 1200px;
		}
		padding-top: 300px;
		@include media-breakpoint-down("md") {
			width: 100vw;
			padding-top: 250px;
		}
	}
	.box {
		@include media-breakpoint-down("lg") {
			width: 100vw;
			justify-content: center;
			text-align: center;
			h2 {
				text-align: center;
			}
		}
	}
	.skills-icon {
		@include media-breakpoint-down("xs") {
			margin-right: 35px;
		}
	}
	h2 {
		text-align: left;
		font: normal normal normal 32px/45px New-Spirit;
		letter-spacing: -0.64px;
		color: #262626;
		margin-top: 40px;
		@include media-breakpoint-only("lg") {
			margin-top: 25px;
			}
		@include media-breakpoint-down("xs") {
			font: normal normal normal 28px/40px New-Spirit;
			margin-right: 151px;
		}
	}
	p {
		text-align: left;
		font: normal normal normal 18px/30px New-Spirit;
		letter-spacing: -0.36px;
		color: #262626;
		opacity: 0.75;
		width: 482px;
		height: 83px;
		margin-top: 24px;
		margin-bottom: 24px;
		@include media-breakpoint-only("lg") {
			height: 115px;
			margin-top: 20px;
			margin-bottom: 0px;
		}
		@include media-breakpoint-down("sm") {
			font: normal normal normal 16px/25px New-Spirit;
			width: 314px;
			height: 120px;
			margin-left: 30px;
		}
	}
	.preview-tiles {
		@include media-breakpoint-down("lg") {
			margin-top: 40px;
			.preview {
				margin-left: auto !important;
				margin-right: auto !important;
			}
		}
	}
	.see-all-btn {
		@include media-breakpoint-down("lg") {
			text-align: center;
		}
		@include media-breakpoint-down("xs") {
			margin-right: 115px;
		}
	}
}
