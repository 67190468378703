.landing {
	background-image: url(../../../imgs/background/yellow-bg.svg);
	background-repeat: no-repeat;
	width: 100vw;
	height: 750px;
	background-position-x: center;
	background-size: cover;
	@include media-breakpoint-down("md") {
		margin-top: 100px;
		height: 800px;
	}
	@media only screen and (min-width: 1367px) {
		height: 800px;
		background-position: bottom;
	}
	.content {
		width: 1366px;
		@include media-breakpoint-only("lg") {
			width: 992px;
		}
		@include media-breakpoint-only("xl") {
			width: 1200px;
		}
		@include media-breakpoint-down("md") {
			width: 100vw;
		}
	}
	.row {
		padding-top: 200px;
		@include media-breakpoint-down("md") {
			padding-top: 50px;
		}
	}
	.left-content {
		justify-content: flex-end;
		@include media-breakpoint-down("md") {
			justify-content: center;
		}
	}
	h1 {
		text-align: left;
		font: normal normal normal 40px/50px New-Spirit;
		letter-spacing: -0.8px;
		color: #383838;
		width: 380px;
		height: 100px;
		@include media-breakpoint-down("md") {
			font: normal normal normal 32px/40px New-Spirit;
			height: 80px;
			width: 300px
		}
	}
	p {
		text-align: left;
		font: normal normal normal 18px/30px New-Spirit;
		letter-spacing: -0.36px;
		color: #262626;
		opacity: 0.75;
		width: 452px;
		height: 113px;
		padding-top: 32px;
		@include media-breakpoint-down("md") {
			font: normal normal normal 16px/25px New-Spirit;
			width: 310px;
			height: 130px;
			padding-top: 22px;
		}
	}
	.box {
		width: 352px;
		height: 313px;
		border-radius: 5px;
		background: $ms-yellow 0% 0% no-repeat padding-box;
		background-image: url(../../../imgs/background/dotted-lines.svg);
		background-repeat: no-repeat;
		box-shadow: 0px 4px 0px #debe27;
		border: 2px solid $ms-yellow;
		text-align: center;
		@include media-breakpoint-down("md") {
			height: 340px;
			margin-top: 50px;
		}
		h3 {
			text-align: center;
			font: normal normal normal 23px/30px New-Spirit;
			letter-spacing: -0.48px;
			color: #262626;
			opacity: 1;
			width: 296px;
			padding-top: 30px;
			@include media-breakpoint-down("md") {
				font: normal normal normal 21px/30px New-Spirit;
				width: 275px;
			}
		}
		p {
			text-align: center;
			font: normal normal normal 16px/25px New-Spirit;
			opacity: 1;
			width: 308px;
			height: 130px;
			padding-top: 10px;
			@include media-breakpoint-down("md") {
				font: normal normal normal 16px/30px New-Spirit;
				width: 275px;
				height: 155px;
			}
		}
	}
}
