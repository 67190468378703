.journal-tile {
	background-image: url(../../../imgs/background/purple-bg.svg);
	background-repeat: no-repeat;
	width: 100vw;
	height: 1500px;
	background-position-x: center;
	background-size: cover;
	@include media-breakpoint-down("md") {
		height: 2400px;
	}
	@include media-breakpoint-only("lg") {
		height: 1700px;
	}
	.content {
		width: 1366px;
		@include media-breakpoint-only("lg") {
			width: 992px;
		}
		@include media-breakpoint-only("xl") {
			width: 1200px;
		}
		padding-top: 200px;
		@include media-breakpoint-down("md") {
			width: 100vw;
		}
	}
	.journal-icon {
		@include media-breakpoint-down("xs") {
			margin-right: 34px;
		}
	}
	h2 {
		text-align: center;
		font: normal normal normal 32px/45px New-Spirit;
		letter-spacing: -0.64px;
		color: #262626;
		margin-top: 32px;
		@include media-breakpoint-down("xs") {
			margin-right: 219px;
		}
		@include media-breakpoint-down("sm") {
			font: normal normal normal 28px/40px New-Spirit;
		}
	}
	p {
		text-align: center;
		font: normal normal normal 18px/30px New-Spirit;
		letter-spacing: -0.36px;
		color: #262626;
		opacity: 0.75;
		width: 720px;
		height: 80px;
		margin-top: 24px;
		margin-bottom: 40px;
		margin-left: auto;
		margin-right: auto;
		@include media-breakpoint-down("xs") {
			font: normal normal normal 16px/25px New-Spirit;
			margin-top: 40px;
			margin-bottom: 40px;
			text-align: left;
			height: 145px;
			width: 314px;
		}
		@include media-breakpoint-only("sm") {
			font: normal normal normal 16px/25px New-Spirit;
			width: 450px;
			height: 115px;
			margin-bottom: 24px;
		}
	}
	.col-lg-3 {
		@include media-breakpoint-up("sm") {
			padding: 0px;
			max-width: 20%;
		}
	}
	.button-container {
		justify-content: center;
		display: flex;
		margin-top: 20px;
		@include media-breakpoint-down("xs") {
			margin-right: 146px;
			margin-top: 0px;
		}
	}
}
