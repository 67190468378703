.about-page {
	.about-mindscape {
		background-image: url(../../imgs/background/about-bg.svg);
		background-repeat: no-repeat;
		width: 100vw;
		height: 700px;
		background-position-x: center;
		background-size: cover;
		padding-top: 150px;
		@include media-breakpoint-down("md") {
			height: 900px;
			padding-top: 170px;
		}
		@media only screen and (min-width: 1367px) {
			height: 750px;
			padding-top: 170px;
			background-position: bottom;
		}
		.happy-face {
			@include media-breakpoint-down("md") {
				width: 200px;
				height: auto;
			}
		}
	}
	.content {
		width: 1366px;
		@include media-breakpoint-only("lg") {
			width: 992px;
		}
		@include media-breakpoint-only("xl") {
			width: 1200px;
		}
		@include media-breakpoint-only("md") {
			width: 90vw;
		}
		@include media-breakpoint-down("sm") {
			width: 100vw;
		}
		h1 {
			text-align: left;
			font: normal normal normal 40px/50px New-Spirit;
			letter-spacing: -0.8px;
			color: #262626;
			opacity: 1;
			@include media-breakpoint-only("md") {
				text-align: center;
				margin-top: 20px;
			}
			@include media-breakpoint-down("sm") {
				margin-top: 40px;
				font: normal normal normal 32px/40px New-Spirit;
			}
		}
		h2 {
			text-align: center;
			font: normal normal normal 32px/45px New-Spirit;
			letter-spacing: -0.64px;
			color: #262626;
			opacity: 1;
			@include media-breakpoint-down("sm") {
				font: normal normal normal 28px/40px New-Spirit;
			}
		}
		p {
			width: 452px;
			height: 113px;
			margin-top: 32px;
			text-align: left;
			font: normal normal normal 18px/30px New-Spirit;
			letter-spacing: -0.36px;
			color: #262626;
			opacity: 0.75;
			@include media-breakpoint-down("sm") {
				width: 312px;
				height: 120px;
				margin-top: 24px;
				font: normal normal normal 16px/25px New-Spirit;
			}
		}
	}

	.feeling-tile {
		height: 500px;
		padding-top: 80px;
		position: relative;
		@include media-breakpoint-down("sm") {
			height: 600px;
			padding-top: 0px;
		}
		h2 {
			width: 444px;
			height: 85px;
			margin-left: auto;
			margin-right: auto;
			@include media-breakpoint-down("sm") {
				width: 315px;
				height: 115px;
			}
		}
		p {
			width: 720px;
			height: 225px;
			margin-left: auto;
			margin-right: auto;
			@include media-breakpoint-down("sm") {
				width: 312px;
				height: 350px;
			}
		}
		.p2 {
			height: 300px;
			@include media-breakpoint-down("sm") {
				height: 500px;
			}
		}
	}
	.founder-tile {
		height: 1250px;
		position: relative;
		@include media-breakpoint-down("sm") {
			height: 1350px;
		}
		img {
			width: 349px;
			height: 522px;
			margin-top: 40px;
			margin-bottom: 50px;
			@include media-breakpoint-down("sm") {
				width: 329px;
				height: 502px;
			}
		}
		p {
			width: 720px;
			height: 150px;
			margin-left: auto;
			margin-right: auto;
			@include media-breakpoint-down("sm") {
				width: 312px;
				height: 230px;
			}
		}
		.p2 {
			height: 85px;
			@include media-breakpoint-down("sm") {
				height: 125px;
			}
		}
		.p3 {
			@include media-breakpoint-down("sm") {
				width: 312px;
				height: 270px;
			}
		}
	}
	.shame {
		@media (min-width: 1300px) {
			height: 750px;
			width: 100vw;
			right: -25px;
			top: -200px;
			position: absolute;
			background-image: url(../../imgs/moods/shame.svg);
			background-repeat: no-repeat;
			background-position: right -50px top 0px;
			background-size: 300px 249px;
			transform: rotate(20deg);
		}
	}
	.anger {
		@media (min-width: 1300px) {
			height: 750px;
			width: 100vw;
			left: -100px;
			position: absolute;
			background-image: url(../../imgs/moods/anger.svg);
			background-repeat: no-repeat;
			background-position: left -50px top 200px;
			background-size: 300px 340px;
			transform: rotate(-10deg);
		}
	}
	.guilt {
		@media (min-width: 1300px) {
			height: 950px;
			width: 100vw;
			right: -30px;
			position: absolute;
			background-image: url(../../imgs/moods/guilt.svg);
			background-repeat: no-repeat;
			background-position: right -50px top 400px;
			background-size: 300px 340px;
			transform: rotate(-5deg);
		}
	}
}
