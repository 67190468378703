.get-involved {
	background-image: url(../../imgs/background/mind-mapper-bg.svg);
	background-repeat: no-repeat;
	background-position-x: center;
	background-size: cover;
	width: 100vw;
	height: 100vh;
	padding-top: 200px;
	@include media-breakpoint-only("md") {
		height: 120vh;
	}
	@include media-breakpoint-down("sm") {
		padding-top: 90px;
		height: 1900px;
	}
	.content {
		width: 1366px;
		@include media-breakpoint-only("lg") {
			width: 992px;
		}
		@include media-breakpoint-only("xl") {
			width: 1200px;
		}
		@include media-breakpoint-down("md") {
			width: 100vw;
		}
	}
	.row {
		@include media-breakpoint-down("md") {
			padding-top: 50px;
		}
	}
	.left-content {
		justify-content: center;
		@include media-breakpoint-down("md") {
			justify-content: center;
		}
	}
	.top-text {
		@include media-breakpoint-down("md") {
			height: 750px
		}
	}
	.happyface {
		width: 144px;
		height: 144px;
		@include media-breakpoint-down("sm") {
			display: flex;
			margin-left: auto;
			margin-right: auto;
		}
	}
	h1 {
		text-align: left;
		font: normal normal normal 40px/50px New-Spirit;
		letter-spacing: -0.8px;
		color: #262626;
		width: 380px;
		height: 50px;
		margin-top: 20px;
		@include media-breakpoint-down("lg") {
			font: normal normal normal 32px/40px New-Spirit;
			height: 4 0px;
			width: 300px;
		}
	}
	.disclaimer {
		padding-top: 5px;
		height: 80px;
		font: normal normal bold 15px/25px New-Spirit;
		@include media-breakpoint-down("sm") {
			height: 100px;
		}
	}
	p {
		text-align: left;
		font: normal normal normal 18px/30px New-Spirit;
		letter-spacing: -0.36px;
		color: #262626;
		opacity: 0.75;
		width: 536px;
		height: 220px;
		padding-top: 20px;
		@include media-breakpoint-down("lg") {
			font: normal normal normal 16px/25px New-Spirit;
			width: 310px;
			height: 280px;
			padding-top: 22px;
		}
	}
	p:last-child {
		@include media-breakpoint-down("sm") {
			height: 70px;
		}
	}
	.box {
		width: 484px;
		height: 620px;
		border-radius: 15px;
		background: $ms-yellow 0% 0% no-repeat padding-box;
		box-shadow: 0px 4px 0px #debe27;
		border: 2px solid $ms-yellow;
		text-align: center;
		padding: 32px;
		padding-top: 4px;
		@include media-breakpoint-down("lg") {
			width: 350px;
			height: 730px;
		}
		.form-label {
			text-align: left;
			font: normal normal normal 18px/30px New-Spirit;
			letter-spacing: -0.36px;
			color: #262626;
			opacity: 1;
			width: 150px;
			padding-top: 10px;
			display: flex;
			@include media-breakpoint-down("lg") {
				padding-top: 15px;
				font: normal normal normal 18px/30px New-Spirit;
				width: 275px;
			}
		}
		.row {
			@include media-breakpoint-down("sm") {
				padding-top: 0px;
			}
		}
		.form-control {
			background: #ffffff 0% 0% no-repeat padding-box;
			border-radius: 5px;
			opacity: 1;
		}
		.form-label::after {
			content: "*";
			color: red;
			margin-left: 4px;
		}
		.messageControl {
			height: 129px;
		}
		.invalid-feedback {
			text-align: left;
			font: normal normal normal 14px/20px New-Spirit;
			height: 25px;
			width: 428px;
			margin-top: 5px;
			margin-bottom: 0px;
			margin-left: auto;
			margin-right: auto;
		}
		.other {
			display: none;
			margin-top: 10px;
		}
		textarea {
			resize: none;
		}
	}
}
