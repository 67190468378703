.about-tile {
	height: 650px;
	@include media-breakpoint-down("md") {
		height: 950px;
	}
	.content {
		width: 1366px;
		@include media-breakpoint-only("lg") {
			width: 992px;
		}
		@include media-breakpoint-only("xl") {
			width: 1200px;
		}
		padding-top: 100px;
		@include media-breakpoint-down("md") {
			width: 100vw;
			padding-top: 0px;
		}
	}
	h2 {
		text-align: left;
		font: normal normal normal 32px/45px New-Spirit;
		letter-spacing: -0.64px;
		color: #262626;
		margin-top: 40px;
		@include media-breakpoint-down("sm") {
			font: normal normal normal 28px/40px New-Spirit;
			margin-left: 30px;
			margin-top: 30px;
		}
	}
	p {
		text-align: left;
		font: normal normal normal 18px/30px New-Spirit;
		letter-spacing: -0.36px;
		color: #262626;
		opacity: 0.75;
		width: 536px;
		height: 280px;
		margin-top: 32px;
		margin-bottom: 32px;
		@include media-breakpoint-only("lg") {
			width: 450px;
		}
		@include media-breakpoint-only("md") {
			width: 414px;
			height: 320px;
		}
		@include media-breakpoint-down("sm") {
			font: normal normal normal 16px/25px New-Spirit;
			width: 314px;
			height: 320px;
			margin-left: 30px;
			margin-top: 24px;
			margin-bottom: 24px;
		}
	}
	.happy-face {
		@include media-breakpoint-down("md") {
			width: 265px;
		}
	}
	Button {
		@include media-breakpoint-down("sm") {
			margin-left: 30px;
		}
	}
}
