.journal-entry-page {
	.content {
		width: 1366px;
		@include media-breakpoint-only("lg") {
			width: 992px;
		}
		@include media-breakpoint-only("xl") {
			width: 1200px;
		}
		padding-top: 150px;
		@include media-breakpoint-down("md") {
			width: 100vw;
		}
		.journeys {
			display: block;
			text-align: center;
			font: normal normal normal 18px/30px New-Spirit;
			letter-spacing: -0.36px;
			color: #965ae2;
			opacity: 1;
			margin-bottom: 1rem;
			@include media-breakpoint-down("md") {
				font: normal normal normal 16px/30px New-Spirit;
			}
		}
		h1 {
			text-align: center;
			font: normal normal normal 32px/45px New-Spirit;
			letter-spacing: -0.64px;
			color: #262626;
			opacity: 1;
			@include media-breakpoint-down("md") {
				font: normal normal normal 28px/40px New-Spirit;
			}
		}
		h2 {
			text-align: center;
			font: normal normal normal 18px/30px New-Spirit;
			letter-spacing: -0.36px;
			color: #262626;
			opacity: 0.75;
			width: 720px;
			margin-top: 32px;
			@include media-breakpoint-down("md") {
				width: 80vw;
				margin-top: 24px;
				font: normal normal normal 16px/25px New-Spirit;
			}
		}
		h3 {
			text-align: left;
			font: normal normal normal 32px/45px New-Spirit;
			letter-spacing: -0.64px;
			color: #262626;
			opacity: 1;
			@include media-breakpoint-down("md") {
				font: normal normal normal 28px/40px New-Spirit;
			}
		}
		p {
			width: 720px;
			margin-top: 32px;
			margin-bottom: 32px;
			text-align: left;
			font: normal normal normal 18px/30px New-Spirit;
			letter-spacing: -0.36px;
			color: #262626;
			opacity: 0.75;
			.primary {
				text-align: center;
			}
			@include media-breakpoint-down("md") {
				width: 80vw;
				margin-top: 24px;
				margin-bottom: 24px;
				font: normal normal normal 16px/25px New-Spirit;
			}
		}
		li {
			width: 680px;
			margin-top: 32px;
			margin-bottom: 32px;
			padding-left:5px;
			text-align: left;
			font: normal normal normal 18px/30px New-Spirit;
			letter-spacing: -0.36px;
			color: #262626;
			opacity: 0.75;
			.primary {
				text-align: center;
			}
			@include media-breakpoint-down("md") {
				width: 312px;
				margin-top: 24px;
				margin-bottom: 24px;
				font: normal normal normal 16px/25px New-Spirit;
			}
		}
		.journal-image {
			text-align: center;
			margin-top: 32px;
			margin-bottom: 32px;
			@include media-breakpoint-down("md") {
				max-width: 312px;
				max-height: 214px;
				margin-top: 24px;
				margin-bottom: 24px;
			}
			img {
				background-repeat: no-repeat;
				border-radius: 14px;
				max-width: 720px;
				max-height: 350px;
				@include media-breakpoint-down("md") {
					max-width: 312px;
					max-height: 214px;
				}
			}
		}
	}
}
