.partner-tile {
    height: 400px;
	@include media-breakpoint-down("md") {
		height: 400px;
	}
	.content {
		width: 1366px;
		@include media-breakpoint-only("lg") {
			width: 992px;
		}
		@include media-breakpoint-only("xl") {
			width: 1200px;
		}
		@include media-breakpoint-down("md") {
			width: 100vw;
		}
	}
	h2 {
		text-align: center;
		font: normal normal normal 32px/45px New-Spirit;
		letter-spacing: -0.64px;
		color: #262626;
		opacity: 1;
	}
    img {
        width: 300px;
    }
	.carousel-indicators {
		margin-bottom: 0!important;
		li {
			background-color: #262626;
		}
	}
}
