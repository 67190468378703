.footer {
	width: 100vw;
	height: auto;
	.wave {
		height: 150px;
		width: 100vw;
		margin-top: -150px;
		background-image: url(../../imgs/background/footer-wave.svg);
		background-repeat: no-repeat;
		background-position-x: center;
		background-size: cover;
		background-position-y: 30px;
        @include media-breakpoint-down("md") {
			width: 100vw;
            background-position-x: -250px;
			margin-top: -250px;
		}
        @include media-breakpoint-between("lg", "xl") {
			margin-top: -163px;
		}
	}
	.yellow-box {
		height: 400px;
		background-color: #ffda2d;
        @include media-breakpoint-down("md") {
			height: 950px;
            margin-top: -25px;
		}
	}
	.content {
		width: 1366px;
		@include media-breakpoint-only("lg") {
			width: 969px;
		}
		@include media-breakpoint-only("xl") {
			width: 1169px;
		}
		padding-top: 50px;
        @include media-breakpoint-down("md") {
			width: 100vw;
		}
		.logo {
			width: 300px;
            margin-bottom: 10px;
            @include media-breakpoint-down("md") {
                width: 200px;
                margin-bottom: 15px;
                margin-left: auto;
                margin-right: auto;
            }
		}
		.mind-disclaimer {
			font: normal normal normal 14px/20px New-Spirit;
			opacity: .75;
			@include media-breakpoint-down("md") {
				margin-left: auto;
				margin-right: auto;
				text-align: center;
				width: 300px;
				height: 60px;
			}
		}
		h3 {
			text-align: left;
			font: normal normal normal 24px/45px New-Spirit;
			letter-spacing: -0.48px;
			color: #262626;
			opacity: 1;
            @include media-breakpoint-down("md") {
                text-align: center;
                padding-top: 15px;
            }
		}
		a {
			text-align: left;
			font: normal normal normal 18px/30px New-Spirit;
			letter-spacing: -0.36px;
			color: #262626;
			opacity: 0.75;
            margin-bottom: 8px;
            @include media-breakpoint-down("md") {
                text-align: center;
            }
		}
		.footer-text {
			text-align: left;
			font: normal normal normal 18px/30px New-Spirit;
			letter-spacing: -0.36px;
			color: #262626;
			opacity: 0.75;
            margin-bottom: 8px;
            @include media-breakpoint-down("md") {
                text-align: center;
            }
		}
		.footer-text:hover {
			text-decoration: underline;
		}
        .media-group {
            @include media-breakpoint-down("md") {
                justify-content: center;
                display: flex;
            }
        }
        .media-icons {
            margin-right: 16px;
            cursor: pointer;
        }
	}
}
