.navbar {
	padding: 0;
	.background {
		background: $ms-yellow 0% 0% no-repeat padding-box;
		top: 0px;
		left: 0px;
		right: 0px;
		width: 100vw !important;
		height: 80px;
		display: flex;
		position: fixed;
		z-index: 999;
		@include media-breakpoint-down("md") {
			width: 100vw !important;
			height: 60px;
			position: fixed;
			z-index: 999;
			padding: 0px;
		}
	}
	.emerg-butt {
		@include media-breakpoint-down("md") {
			top: 0px;
			width: 100vw;
			position: fixed;
			margin-top: 60px;
			z-index: 999;
			padding: 0px;
		}
	}
	.items {
		width: 1370px;
		@include media-breakpoint-down("md") {
			background: $ms-yellow 0% 0% no-repeat padding-box;
			height: 100vh;
			width: 100vw;
			top: 0;
			margin-top: 110px;
			position: fixed;
			z-index: 889;
			.column {
				flex-direction: column;
				justify-content: center;
				display: flex;
				position: absolute;
			}
		}
	}
	.logo {
		height: 28px;
		@include media-breakpoint-down("md") {
			margin-top: 0.84rem !important;
		}
	}
	.heading {
		color: #262626;
		letter-spacing: -0.32px;
		opacity: 1;
		font-size: 24px;
		font-weight: bold;
		text-decoration: underline;
		text-align: center;
		padding-bottom: 24px;
	}
	.title {
		color: #262626;
		letter-spacing: -0.32px;
		opacity: 1;
		@include media-breakpoint-down("md") {
			font-size: 24px;
			@media screen and (max-height:600px){
				font-size: 20px;
				line-height: 30px;
			}
			text-align: center;
			max-width: 165px;
			padding-bottom: 24px;
		}
	}
	.title:hover {
		color: #797979;
		letter-spacing: -0.32px;
		opacity: 1;
		cursor: pointer;
		user-select: none;
		text-decoration: none;
	}

	.title-col {
		min-width: 60px;
		padding: 20px;
		@include media-breakpoint-only("lg") {
			min-width: 50px;
			padding: 15px;
		}
	}

	.nav-logo {
		@include media-breakpoint-only("lg") {
			flex: 0 0 15%;
			max-width: 15%;
			svg {
				height: 20px;
			}
		}
	}

	.nav-links {
		@include media-breakpoint-only("lg") {
			flex: 0 0 53%;
			max-width: 53%;
			a {
				font: normal normal normal 14px/28px New-Spirit;
			}
			.title-col {
				padding: 10px;
			}
			
		}
		@include media-breakpoint-only("xl") {
			flex: 0 0 53%;
			max-width: 53%;
			a {
				font: normal normal normal 14px/40px New-Spirit;
			}
		}
	}

	.nav-help {
		@include media-breakpoint-only("lg") {
			.std-button {
				font: normal normal normal 14px/28px New-Spirit;
				width: 141px;
			}
			flex: 0 0 32%;
			max-width: 32%;
			.row {
				margin-right: 0;
				margin-left: 0;
			}
			.col {
				padding-right: 0;
				padding-left: 0;
			}
		}
		@include media-breakpoint-only("xl") {
			flex: 0 0 28%;
			max-width: 28%;
		}
		@media (min-width: 1200px) {
			.std-button {
				width: 142px;
			}
		}
		@media (min-width: 1326px) {
			.std-button {
				width: 160px;
			}
		}
	}
}

.modal-backdrop.show {
	opacity: 0.7;
}

.modal-dialog.help-modal {
	max-width: 720px !important;
	@include media-breakpoint-down("md") {
		max-width: 314px !important;
		max-height: 100vh !important;
	}
}

.help-modal {
	width: 720px;
	height: 302px;
	margin-top: 60px;
	@include media-breakpoint-down("md") {
		@media screen and (max-height:600px){
			margin-top: 15px;
		}
		width: 314px;
		height: 467px !important;
		margin-left: auto;
		margin-right: auto;
	}

	.modal-content {
		height: 382px;
		background-color: $ms-yellow;
		box-shadow: 0px 4px 0px #debe27;
		border: 2px solid $ms-yellow;
		border: none;
		@include media-breakpoint-down("md") {
			height: 580px;
			@media screen and (max-height:600px){
				height: 520px;
			}
		}
		.modal-header {
			height: 150px;
			@media screen and (max-height:600px){
				height: 100px;
			}
			justify-content: center;
			border: none;
			.modal-title {
				margin-top: 32px;
				text-align: center;
				font: normal normal normal 40px/50px New-Spirit;
				letter-spacing: -0.8px;
				color: #262626;
				opacity: 1;
				@include media-breakpoint-down("md") {
					width: 248px;
					margin-top: 22px;
					font: normal normal normal 25px/30px New-Spirit;
					@media screen and (max-height:600px){
						font: normal normal normal 20px/25px New-Spirit;
					}
				}
			}
		}
		.modal-body {
			height: 130px;
			border: none;
			justify-content: center;
			display: flex;
			@include media-breakpoint-down("md") {
				height: 160px;
			}
			p {
				width: 536px;
				text-align: center;
				font: normal normal normal 18px/30px New-Spirit;
				letter-spacing: -0.36px;
				color: #262626;
				opacity: 0.75;
				@include media-breakpoint-down("md") {
					width: 248px;
					height: 160px;
					font: normal normal normal 16px/30px New-Spirit;
					@media screen and (max-height:600px){
						font: normal normal normal 15px/25px New-Spirit;
						height: 100px;
					}
				}
			}
		}
		.modal-footer {
			justify-content: center;
			display: flex;
			border: none;
			Button {
				margin-bottom: 12px;
				@include media-breakpoint-up("lg") {
					width: 161px;
				}
			}
			a {
				text-align: center;
				font: normal normal normal 18px/30px New-Spirit;
				letter-spacing: -0.36px;
				color: #262626;
				margin-top: auto;
				margin-bottom: auto;
				text-decoration: none;
			}
		}
	}
	.phone-btn {
		@include media-breakpoint-down("md") {
			width: 175px;
		}
	}
}
