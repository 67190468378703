.journal-page {
	.journal-top {
		&--purple {
			background-image: url(../../imgs/background/journal-bg-top.svg);
		}
		&--green {
			background-image: url(../../imgs/background/skill-bg-top.svg);
		}
		&--blue {
			background-image: url(../../imgs/background/video-bg-top.svg);
		}
		background-repeat: no-repeat;
		width: 100vw;
		height: 550px;
		background-position-x: center;
		background-size: cover;
		padding-top: 150px;
		@include media-breakpoint-down("md") {
			height: 650px;
			padding-top: 160px;
		}
		@media only screen and (min-width: 1367px) {
			height: 600px;
			padding-top: 170px;
			background-position: bottom;
		}
		&--blue {
			@include media-breakpoint-only("lg") {
				height: 580px;
			}
			@include media-breakpoint-only("md") {
				height: 750px;
			}
			@include media-breakpoint-down("sm") {
				height: 750px;
			}
		}
		.content {
			width: 100vw;
			@include media-breakpoint-down("md") {
				width: 100vw;
			}
			.box {
				text-align: center;
			}
			h1 {
				font: normal normal normal 40px/45px New-Spirit;
				letter-spacing: -0.8px;
				color: #262626;
				opacity: 1;
				@include media-breakpoint-down("md") {
					letter-spacing: -0.64px;
					font: normal normal normal 32px/40px New-Spirit;
				}
			}
			p {
				width: 760px;
				margin-top: 24px;
				font: normal normal normal 18px/30px New-Spirit;
				letter-spacing: -0.36px;
				color: #262626;
				opacity: 0.75;
				@include media-breakpoint-down("md") {
					width: 312px;
					margin-top: 16px;
					letter-spacing: -0.32px;
					font: normal normal normal 16px/25px New-Spirit;
				}
				a {
					width: 720px;
					margin-top: 24px;
					font: normal normal normal 18px/30px New-Spirit;
					letter-spacing: -0.36px;
					opacity: 0.75;
					text-decoration: underline;
					@include media-breakpoint-down("sm") {
						width: 312px;
						margin-top: 16px;
						letter-spacing: -0.32px;
						font: normal normal normal 16px/25px New-Spirit;
					}
				}
			}
			a:hover {
				opacity: 1;
			}
			.std-button {
				width: 223px;
				margin-top: 16px;
			}
		}
	}
	.journals-container {
		@include media-breakpoint-up("xl") {
			width: 1088px;
		}
		@include media-breakpoint-only("lg") {
			width: 969px;
		}
		@media (min-width: 1500px) {
			width: 1188px;
		}
		&--margin-bottom {
			margin-bottom: 10rem;
			@include media-breakpoint-down("md") {
				margin-bottom: 12rem;
			}
		}
	}
	.journals-pagination {
		text-align: center;
		margin-bottom: 15px;
		.prev-page {
			svg {
				transform: scaleX(-1);
			}
		}
		.pages {
			font: normal normal normal 25px/30px New-Spirit;
			letter-spacing: -0.5px;
			user-select: none;
		}
		.inactive {
			cursor: pointer;
		}
		.active {
			color: #c9c9c9;
		}
		.std-button {
			@include media-breakpoint-up("lg") {
				width: 169px;
			}
		}
	}
	.tiles-container {
		max-width: 1088px;
		margin-bottom: 200px;
		@media (min-width: 1500px) {
			max-width: 1300px;
		}
		h3 {
			text-align: center;
			font: normal normal normal 32px/45px New-Spirit;
			letter-spacing: -0.64px;
			color: #262626;
			margin-top: 20px;
			@include media-breakpoint-down("md") {
				font: normal normal normal 20px/30px New-Spirit;
			}
		}
		p {
			width: 368px;
			height: 53px;
			text-align: center;
			font: normal normal normal 18px/30px New-Spirit;
			letter-spacing: -0.36px;
			color: #262626;
			opacity: 0.75;
			margin-top: 24px;
			margin-bottom: 24px;
			margin-left: auto;
			margin-right: auto;
			@include media-breakpoint-down("md") {
				width: 268px;
				height: 80px;
				font: normal normal normal 16px/30px New-Spirit;
				margin-left: 24px;
			}
		}
		.button-container {
			justify-content: center;
			display: flex;
			margin-top: 20px;
			@include media-breakpoint-down("md") {
				justify-content: center;
				margin-top: 16px;
				margin-left: 0px;
			}
		}
		.get-involved-journal {
			transform: scaleX(-1);
			box-shadow: 0px 4px 0px #963ee2;
			border: 2px solid $ms-purple;
			width: 536px;
			height: 420px;
			background-color: $ms-purple;
			border-radius: 10px;
			margin-top: 50px;
			.inner {
				transform: inherit;
			}
			h3 {
				color: white;
				margin-top: 99px;
			}
			p {
				color: white;
			}
			@include media-breakpoint-between("sm", "md") {
				width: 316px;
				height: 425px;
			}
			@include media-breakpoint-down("xs") {
				width: 316px;
				height: 398px;
			}
		}
		.get-involved-journal::before {
			content: "";
			background-image: url(../../imgs/background/dotted-lines.svg);
			background-size: 600px 520px;
			background-repeat: no-repeat;
			position: absolute;
			top: 0px;
			right: 0px;
			bottom: 0px;
			left: 0px;
			opacity: 0.25;
		}
	}

	.load-more-btn {
		@include media-breakpoint-down("md") {
			width: 150px;
		}
	}
}
