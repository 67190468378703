.dropdown {
	.btn-yellow {
		border-radius: 24px;
		background: $ms-yellow 0% 0% no-repeat padding-box;
		box-shadow: 0px 4px 0px #debe27;
		border: 2px solid $ms-yellow;
		color: black;
		width: 215px;
		text-align: center;
		font: normal normal normal 18px/30px New-Spirit;
		white-space: pre-wrap;
	}
	.dropdown-menu {
		border-radius: 24px;
		background: $ms-yellow 0% 0% no-repeat padding-box;
		box-shadow: 0px 4px 0px #debe27;
		border: 2px solid $ms-yellow;
	}
	.dropdown-item {
		border: 2px solid $ms-yellow;
		color: black;
		width: 210px;
		text-align: left;
		font: normal normal normal 18px/30px New-Spirit;
		white-space: pre-wrap;
		padding: .4rem .5rem!important;
	}
	.dropdown-item:first-child {
		border-top-left-radius: 7px;
		border-top-right-radius: 7px;
	}
	.dropdown-item:last-child {
		border-bottom-left-radius: 7px;
		border-bottom-right-radius: 7px;
	}
}

.ms-dropdown-menu {
	margin-top: -2.9rem;
}

select {
	border-radius: 24px;
	background: $ms-yellow 0% 0% no-repeat padding-box;
	box-shadow: 0px 4px 0px #debe27;
	border: 2px solid $ms-yellow;
	color: black;
	width: 200px;
	text-align: center;
	font: normal normal normal 18px/30px New-Spirit;
}
