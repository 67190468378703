.icon-button {
	height: 24px;
	width: 52px;
	font-size: 25px;
	padding: 8px;
	color: black;
	vertical-align: middle;
	text-align: center;
	position: fixed;
	right: 20px;
	cursor: pointer;
}
